@import "../../styles/variables";

.container {
	text-align: center;
}

.container a {
	margin: 8px 8px 0 0;
}

.container a:last-of-type {
	margin-right: 0;
}

.sprite {
	display: inline-block;
	width: 30px;
	height: 30px;
	background: url("/images/social-icon-sprite.png") no-repeat;
}

.sprite span {
	display: none;
}

.title {
	font-family: $font-subheading;
	margin: 10px 0 0 0;
	font-size: 18px;
	line-height: 24px;
}

@include large() {
	.title {
		font-size: 24px;
		line-height: 28px;
	}

	.container a:hover {
		transform: scale(1.1);
		transition: all 0.2s;
	}
}