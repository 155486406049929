@import "../../styles/variables";

.container {
	padding: 20px;
	display: flex;
	flex-direction: column;
}

.title {
	display: none;
}

@include large() {
	.inner {
		max-width: 1600px;
		margin: auto;
		padding: 0 40px;
	}

	.container {
		flex-direction: row;
		flex-wrap: wrap;
		padding: 0;
	}

	.title {
		display: block;
		padding: 80px 0;
	}

	.title {
		display: block;
		padding: 80px 0;
	}

	.container h2 {
		display: block;
		width: 100%;
	}

	.video {
		width: calc(50% - 40px);
	}

	.video:nth-child(even) {
		margin-right: 40px;
	}
}