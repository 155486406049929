@import "../../styles/variables";

.container {
	position: relative;
	z-index: 100;
}

.inner {
	height: 70px;
	display: flex;
	align-items: center;
	padding: 0 25px;
}

.right {
	text-align: right;
}

.logo {
	display: inline-block;
	color: $color-red !important;
	font-family: $font-heading;
	font-size: 22px;
}

.logo:link, .logo:visited {
	text-decoration: none;
}

.overlay {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background: $color-black;
	display: none;
}

.navItems {
	margin-top: 120px;
	padding: 0 40px;
}

.navItem {
	padding: 25px 0;
	border-bottom: 0.5px solid $color-white;
	display: flex;
	align-items: center;
}

.navItem:link, .navItem:visited {
	text-decoration: none;
}

.navItem span {
	color: $color-white;
	font-family: $font-subheading;
	font-size: 20px;
	line-height: 28px;
	display: inline-block;
}

.icons {
	position: absolute;
	left: 0;
	bottom: 30px;
	display: flex;
	justify-content: center;
	width: 100%;
}

@media (max-width: 375px) {
	.navItems {
		margin-top: 50px;
	}
}

@include large() {
	.container {
		background: $color-black;
	}

	.inner {
		height:100px;
		justify-content: center;
		margin: 0;
		padding: 0;
	}

	.inner li {
		width: 150px;
		text-align: center;
		font-family: $font-subheading;
		list-style: none;
	}

	.inner li a {
		text-decoration: none;
	}

	.inner li a:hover {
		color: $color-red;
	}

	.inner .logo {
		text-align: center;
		font-size: 28px;
		line-height: 24px;
		width: 160px;
		font-family: $font-heading;
	}

	.logo a {
		display: block;
		color: $color-red;
	}

	.logo a:link, .logo a:visited {
		text-decoration: none;
	}

	.logo a:hover {
		transform: scale(1.05);
	}
}

