@import "../../styles/variables";

.container {
	max-width: 1600px;
	margin: auto;
	position: relative;
}

.image1 {
	width: 100%;
	margin-left: -16%;
	max-width: 320px;
}

.image2 {
	max-height: 220px;
	margin-left: -215px;
	margin-top: -50px;
	position: relative;
	z-index: -1;
}

.image3 {
	max-height: 250px;
	margin-left: 175px;
	margin-top: -100px;
	position: relative;
	z-index: -1;
}

@media (min-width: 750px) {
	.image3 {
		margin-left: 275px;
	}
}

@include large() {
	.container {
		height: 1000px;
		padding-top: 150px;
	}

	.heading {
		position: relative;
		z-index: 3;
		text-align: center;
		margin-bottom: 80px;
	}

	.imageTopLeft, .imageTopRight, .imageCenterLeft, .imageBottomLeft, .imageBottomRight {
		position: absolute;
		display: inline-block;
	}

	.imageTopLeft img, .imageTopRight img, .imageCenterLeft img, .imageBottomLeft img, .imageBottomRight img {
		position: relative;
		z-index: 2;
	}

	.bgTopLeft, .bgTopRight, .bgCenterLeft, .bgBottomLeft, .bgBottomRight {
		position: absolute;
		width: 100%;
		height: 100%;
		background: $color-black;
		z-index: 1;
	}

	.imageTopLeft {
		top: -40px;
		left: 100px;
	}

	.imageTopLeft img {
		width: 400px;
	}

	.bgTopLeft {
		top: 35px;
		left: -35px;
	}

	.imageTopRight {
		top: -90px;
		right: -40px;
	}

	.imageTopRight img {
		width: 480px;
	}

	.bgTopRight {
		left: -70px;
		top: 70px;
	}

	.imageCenterLeft {
		top: 380px;
		left: -100px;
	}

	.imageCenterLeft img {
		width: 478px;
	}

	.bgCenterLeft {
		display: none;
	}

	.imageBottomLeft {
		width: 460px;
		top: 380px;
		right: 550px;
		z-index: 3;
	}

	.imageBottomLeft img {
		width: 460px;
	}

	.bgBottomLeft {
		top: -40px;
		left: -60px;
	}

	.imageBottomRight {
		top: 500px;
		right: 0;
	}

	.imageBottomRight img {
		width: 382px;
	}

	.bgBottomRight {
		top: -60px;
		left: -80px;
	}
}