@font-face {
	font-family: 'rflexregular';
	src: url('../fonts/r-flex-regular.otf') format('opentype'),
	url('../fonts/r-flex-regular.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'rflexbold';
	src: url('../fonts/r-flex-bold.otf') format('opentype'),
	url('../fonts/r-flex-bold.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'rflexblack';
	src: url('../fonts/r-flex-black.otf') format('opentype'),
	url('../fonts/r-flex-black.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'futuramedium';
	src: url('../fonts/futura-medium.ttf') format('truetype'),
	url('../fonts/futura-medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

body {
	font-family: $font-regular;
	color: $color-white;
	font-size: 16px;
	line-height: 22px;
	font-synthesis: none;
}

.bold {
	font-family: $font-heading;
}

.regular {
	font-family: $font-regular;
}

.font-tiny {
	font-size: 12px;
	line-height: 16px;
}

.font-smaller {
	font-size: 14px;
	line-height: 18px;
}

.font-small {
	font-size: 16px;
	line-height: 22px;
}

.font-medium {
	font-size: 18px;
	line-height: 24px;
}

.font-large {
	font-size: 20px;
	line-height: 26px;
}

.font-larger {
	font-size: 22px;
	line-height: 28px;
}

.font-xlarge {
	font-size: 24px;
	line-height: 30px;
}

a {
	transition: all 0.2s;
}

a:link, a:visited {
	color: $color-white;
	text-decoration: underline;
}

a:hover, a:active {
	color: $color-white;
	text-decoration: none;
}

h1 {
	font-size: 24px;
	line-height: 30px;
	font-family: $font-heading;
}

h2 {
	font-size: 22px;
	line-height: 28px;
	font-family: $font-subheading;
}

@include large() {
	h1 {
		font-size: 24px;
		line-height: 32px;
	}

	h2 {
		font-size: 24px;
		line-height: 32px;
	}
}

.text-right {
	text-align: right;
}