@import-normalize;
@import "~bootstrap/scss/bootstrap";
@import "../../styles/app";

html, body {
	margin: 0;
	height: 100%;
	width: 100%;
	overflow-x: hidden;
}

body {
	background: $color-black;
}

@include large() {
	body {
		background: $color-dark-grey;
	}
}

.App {
	//max-width: 1800px;
	//margin: auto;
	//overflow: hidden;
}

button {
	border: none;
	background: none;
	padding: 0;
	outline: none;
	display: inline-block;
}