@import "../../styles/variables";

.container {
	padding: 40px 30px;
	background: $color-dark-grey;
	position: relative;
}

.inner {
	max-width: 1400px;
	margin: auto;
}

.nav {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 40px;
	flex-wrap: wrap;
	text-align: center;
}

.nav a {
	font-size: 24px;
	font-family: $font-subheading;
	margin: 15px 0;
	text-transform: uppercase;
	display: inline-block;
	width: 50%;
}

.nav a:link, .nav a:visited {
	text-decoration: none;
}

.nav a:hover {
	color: $color-red;
}

.links {
	flex-grow: 1;
	max-width: 1600px;
	margin-top: 60px;
	padding-bottom: 30px;
}

.links h3 {
	font-size: 24px;
	font-family: $font-subheading;
	margin: 0 0 20px 0;
}

.links a {
	font-size: 16px;
	line-height: 25px;
	color: $color-pink;
	display: inline-block;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.links a:link, .links a:visited {
	text-decoration: none;
}

.links a:hover {
	color: $color-white;
}

.josh {
	text-align: center;
	position: absolute;
	bottom: 20px;
	left: 0;
	width: 100%;
	font-size: 15px;
}

@include large() {
	.container {
		padding: 80px 60px;
		background: $color-black;
	}

 	.nav a {
		margin: 0 20px;
		width: auto;
	}

	.links {
		padding-bottom: 10px;
	}

	.links a {
		font-size: 18px;
	}
}