@import "../../styles/variables";

.container {

}

.container h2 {
	margin-bottom: 30px;
}

.cover {
	border-radius: 5px;
	overflow: hidden;
	width: calc(100% - 80px);
	margin: auto;
}

.cover img {
	width: 100%;
}

.listen {
	display: none;
}

.btn {
	border: 2px solid $color-aqua;
	width: 260px;
	height: 50px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	margin-top: 15px;
	font-family: $font-subheading;
	transition: all 0.2s;
}

.btn:visited, .btn:link {
	color: $color-white;
	text-decoration: none;
}

.btn:hover {
	transform: scale(1.05);
}

.btn img {
	width: 80px;
	height: auto;
	margin-left: 5px;
}

@include large() {
	.mainWrapper {
		max-width: 1800px;
		margin: auto;
		display: flex;
		align-items: center;
	}

	.container {
		width: 50%;
		background: $color-black;
		padding: 60px;
		border-radius: 35px;
	}

	.details {
		display: flex;
		margin-top: 60px;
		justify-content: center;
		align-items: center;
	}

	.cover {
		margin-right: 60px;
	}

	.cover img {
		border-radius: 10px;
	}

	.listen {
		display: block;
		width: 50%;
	}

	.wave {
		margin-bottom: 60px;
		width: 100%;
	}
}