// Colors
$color-white: #FFFFFF;
$color-black: #000000;
$color-dark-grey: #110C0F;
$color-grey: #2B2629;
$color-mid-grey: #5A5A5A;
$color-red: #FF0022;
$color-pink: #FF8393;
$color-aqua: #97CEC8;

// Typography
$font-heading: "rflexblack", sans-serif;
$font-subheading: "rflexbold", sans-serif;
$font-regular: "futuramedium", sans-serif;

// Breakpoints
@mixin tablet() {
	@media (min-width: 768px) and (max-width: 1024px) {
		@content;
	}
}

@mixin large() {
	// Large devices (desktops, 992px and up)
	@media (min-width: 992px) {
		@content;
	}
}

@mixin xlarge() {
	// Extra large devices (large desktops, 1200px and up)
	@media (min-width: 1200px) {
		@content;
	}
}
