@import "../../styles/variables";

.button {
	background: $color-black;
	border: 1.5px solid $color-red;
	border-radius: 7px;
	font-size: 18px;
	line-height: 24px;
	font-family: $font-subheading;
	min-width: 200px;
	height: 60px;
	padding: 0 30px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s;
}

.button:link, .button:visited {
	text-decoration: none;
}

.button:hover {
	transform: scale(1.05);
}

@include large() {
	.button {
		min-width: 260px;
		height: 70px;
		border-radius: 10px;
		border: 3px solid $color-red;
		font-size: 20px;
	}
}