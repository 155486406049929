@import "../../styles/variables";

.container {
	overflow: visible;
	position: relative;
	text-align: center;
	display: inline-block;
}

.title, .reflection {
	font-family: $font-heading;
	color: $color-red;
	font-size: 38px;
	line-height: 38px;
	margin: 0;
	display: block;
	white-space: nowrap;
}

.title {
	opacity: 1;
}

.title.large, .reflection.large {
	font-size: 55px;
	line-height: 55px;
}

.reflection {
	position: absolute;
	left: 0;
	top: 0;
}

.reflection:nth-of-type(1) {
	opacity: 0.10;
	left: -30px;
	top: 14px;
}

.reflection:nth-of-type(2) {
	opacity: 0.09;
	left: 2px;
	top: -36px;
}

.reflection:nth-of-type(3) {
	opacity: 0.15;
	left: 47px;
	top: 33px;
}

.reflection:nth-of-type(4) {
	opacity: 0.13;
	left: 45px;
	top: -20px;
}

.reflection:nth-of-type(5) {
	opacity: 0.13;
	left: 7px;
	top: 2px;
}

.reflection:nth-of-type(6) {
	opacity: 0.25;
	left: -2px;
	top: 4px;
}

@include large() {
	.title, .reflection {
		font-size: 48px;
		line-height: 50px;
	}

	.title.large, .reflection.large {
		font-size: 72px;
		line-height: 70px;
	}
}