@import "../../styles/variables";

.container {
	padding: 60px 0 40px;
}

.cta {
	position: relative;
	text-align: center;
	margin-bottom: 40px;
}

.cta a {
	background: $color-red;
	z-index: 2;
}

.cta img {
	position: absolute;
	width: 100%;
	top: 0;
	z-index: 1;
}

.waveLeft {
	left: calc(50% + 100px);
}

.waveRight {
	right: calc(50% + 100px);
	transform: rotate(180deg);
}

.links {
	display: none;
}

@include large() {
	.container {
		padding: 80px 0;
	}

	.cta {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 125px;
		margin: 60px 0 0 0;
	}

	.cta img {
		height: 125px;
	}

	.cta a {
		background: $color-black;
	}

	.waveLeft {
		left: calc(25% + 130px);
	}

	.waveRight {
		right: calc(25% + 130px);
	}

	.links {
		display: block;
		margin-top: 90px;
	}
}