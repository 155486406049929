@import "../../styles/variables";

.video {
	margin: 0 0 20px 0;
	padding: 0 0 20px 0;
	overflow: hidden;
	width: 100%;
}

.video p {
	font-size: 18px;
	line-height: 24px;
}