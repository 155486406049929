@import "../../styles/variables";

.title {
	display: none;
}

.form {

}

.input {
	margin-bottom: 22px;
}

.input label {
	display: block;
	margin-bottom: 5px;
	font-size: 14px;
}

.input input, .input textarea {
	width: 100%;
	border-radius: 5px;
	outline: none;
	border: 0.5px solid $color-white;
	background: $color-grey;
	font-size: 15px;
	color: $color-white;
}

.input input {
	height: 54px;
	display: flex;
	justify-content: center;
	padding: 0 15px;
}

.input textarea {
	height: 158px;
	padding: 15px;
}

.alert {
	font-size: 14px;
	padding: 10px 15px;
}

.btn {
	background: $color-red;
	border-radius: 7px;
	font-size: 18px;
	line-height: 24px;
	font-family: $font-subheading;
	min-width: 200px;
	height: 60px;
	padding: 0 30px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s;
	color: $color-white;
}

.btn:hover {
	transform: scale(1.05);
}

.loader {
	width: 200px;
	display: none;
}

.loader img {
	width: 70px;
}

@include large() {
	.inner {
		max-width: 1600px;
		margin: auto;
		padding: 0 40px;
	}

	.bg {
		padding: 70px 150px;
		background: $color-mid-grey;
		border-radius: 7px;
	}

	.title {
		display: flex;
		padding: 80px 0;
		align-items: center;
	}
}