@import "../../styles/variables";

.image {
	height: 330px;
	width: 100%;
	overflow: hidden;
	position: relative;
}

.image img {
	position: relative;
	z-index: 1;
	height: 100%;
}

.header {
	position: absolute;
	z-index: 2;
	left: 30px;
	bottom: 20px;
}

.copy {
	padding: 0 20px;
	margin: 20px 0;
}

.copy a:link, .copy a:visited {
	color: $color-red;
	text-decoration: underline;
}

.copy a:hover {
	text-decoration: none;
}

.desktopCopy, .desktopHeading, .letsChat {
	display: none;
}

@include large() {
	.inner {
		max-width: 1600px;
		margin: auto;
		padding-bottom: 260px;
	}

	.desktopHeading {
		display: block;
		margin: 50px 0 60px 0;
	}

	.image {
		height: 465px;
		text-align: right;
		width: 824px;
		flex-shrink: 0;
		overflow: visible;
	}

	.desktopCopy {
		display: block;
		padding: 0 40px 0 0;
		flex-shrink: 1;
	}

	.header, .copy {
		display: none;
	}

	.excerpt {
		position: absolute;
		top: 80%;
		left: -20px;
		font-size: 40px;
		line-height: 48px;
		font-family: $font-subheading;
		color: $color-red;
		z-index: 3;
		width: 75%;
		text-align: left;
	}

	.letsChat {
		display: block;
		padding: 80px 0;
		text-align: center;
	}
}