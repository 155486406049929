@import "../../styles/variables";

.container {
	padding: 20px 0;
}

.container :global(.carousel__dot) {
	background: $color-white;
	opacity: 0.3;
	border-radius: 50%;
	width: 8px;
	height: 8px;
	box-shadow: 1px 1px 2px rgb(0 0 0 / 90%);
	transition: opacity .25s ease-in;
	margin: 0 8px;
}

.container :global(.carousel__dot--selected) {
	opacity: 1;
}

.title {
	display: none;
}

.promos {
	margin-top: 40px;
}

.music {
	overflow: scroll;
	max-height: 500px;
}

.music table {
	min-width: 900px;
}

.song {
	cursor: pointer;
	padding: 10px 20px;
}

.song:nth-child(odd) {
	background: $color-grey;
}

.song a {
	min-width: 160px;
	height: 44px;
	border: 1px solid $color-red;
	font-size: 16px;
	font-family: $font-regular;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	text-decoration: none;
	background: $color-black;
	transition: all 0.2s;
}

.song a:hover {
	transform: scale(1.05);
}

.active {
	color: $color-red;
}

.music .rhap_additional-controls {
	display: none;
}

.player {
	position: relative;
}

.player :global(.rhap_repeat-button) {
	display: none;
}

.player :global(.rhap_container) {
	background: $color-grey;
}

.player :global(.rhap_time) {
	color: $color-white;
}

.playerTitle {
	display: none;
}

.albums {
	margin: 40px 0;
}

.album {
	padding: 0 10px;
	text-align: center;
}

.album img {
	width: 216px !important;
	height: 216px;
	border-radius: 10px;
}

.albumTitle {
	margin: 0 0 6px 0;
	font-family: $font-heading;
	font-size: 20px;
	line-height: 26px;
}

.subTitle {
	font-family: $font-regular;
}

.btnBack, .btnNext {
	position: absolute;
	top: 120px;
	display: none;
}

.btnBack button, .btnNext button {
	width: 0;
	height: 0;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	transition: all 0.2s;
}

.btnBack button[disabled], .btnNext button[disabled] {
	opacity: 0.3;
}

.btnBack {
	left: -30px;
}

.btnBack button {
	border-right: 15px solid $color-aqua;
}

.btnNext {
	right: -30px;
}

.btnNext button {
	border-left: 15px solid $color-aqua;
}

.listen {
	border: 2px solid $color-aqua;
	width: 215px;
	height: 50px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	margin-top: 15px;
	font-family: $font-subheading;
	transition: all 0.2s;
}

.listen:visited, .listen:link {
	color: $color-white;
	text-decoration: none;
}

.listen:hover {
	transform: scale(1.05);
}

.listen img {
	width: 80px !important;
	height: auto;
	margin-left: 5px;
}

.video {
	width: 90%;
	margin: auto;
}

.videoCarousel {
	width: 90%;
	margin: auto;
}

.videoCarousel .btnBack, .videoCarousel .btnNext {
	display: block;
	top: 170px;
	z-index: 30;
}

.videoCarousel .btnBack {
	left: -10px;
}

.videoCarousel .btnNext {
	right: -10px;
}

.videoCarousel .btnBack button {
	border-right-color: $color-red;
}

.videoCarousel .btnNext button {
	border-left-color: $color-red;
}

@include tablet() {
	.btnBack button, .btnNext button {
		border-top: 20px solid transparent;
		border-bottom: 20px solid transparent;
	}

	.btnBack button {
		border-right: 20px solid $color-aqua;
	}

	.btnNext button {
		border-left: 20px solid $color-aqua;
	}

	.videoCarousel .btnBack {
		left: -15px;
	}

	.videoCarousel .btnNext {
		right: -15px;
	}
}

@include large() {
	.inner {
		max-width: 1600px;
		margin: auto;
		padding: 0 40px;
	}

	.title {
		display: flex;
		padding: 80px 0;
		align-items: center;
	}

	.video {
		margin: 0 40px 40px 0;
		width: calc(50% - 40px);
	}

	.video:nth-child(even) {
		margin-right: 0;
	}

	.albums .btnBack, .albums .btnNext {
		display: block;
	}

	.playerTitle {
		display: block;
		position: absolute;
		left: 15px;
		bottom: 15px;
	}

	.music {
		max-height: 597px;
	}
}

@media (min-width: 992px) and (max-width: 1500px) {
	.albumTitle {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.album :global(.font-small) {
		font-size: 14px;
	}
}

